<template>
  <div :class="`m2 wrapper wrapper--default`">
    <div class="p-2">
      <Loading v-if="loading" />
      <div v-else>
        <b-button v-b-modal.create class="m-2"><i class="fas fa-sticky-note" /> + Ongeval melden</b-button>
        <table :class="`w-100 table--default table__border--default`" border=1 frame=void rules=rows>
          <thead :class="`table__head--default`">
            <tr>
              <th class="table__head--text table__head--md">Rapporteur</th>
              <th style="width: 100px;" class="table__head--text table__head--md">Datum</th>
              <th style="width: 100px;" class="table__head--text table__head-md">Bestand</th>
              <th class="table__head--text">Ongeval</th>
              <th class="table__head--text">Reactie</th>
              <th style="width: 100px;" class="table__head--text"
                v-if="checkroles(['admin', 'veiligheid'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)">
                Verwijderen?
              </th>
              <th style="width: 100px;" class="table__head--text"
                v-if="checkroles(['admin', 'veiligheid'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)">
                Reactie
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, key) in sales_order_notes" :key="key" class="table__row--color">
              <td class="table__cell--default">
                {{ item.gebruiker }}
              </td>
              <td class="table__cell--default">{{ item.datum }}</td>
              <td class="table_-cell--default" v-if="item.bestand == 'bezig met uploaden...'">
                {{ item.bestand }}
              </td>
              <td class="table_-cell--default" v-else-if="item.bestand == null"></td>
              <td class="table__cell--default text-center" v-else><a target="_blank"
                  :href="`https://kleyn-intranet.s3.eu-west-1.amazonaws.com/sales-order-notities/${item.bestand}`"
                  download>Bestand</a></td>
              <td class="table__cell--default">
                <div class="notes__textbox">
                  {{ item.notitie }}
                </div>
              </td>
              <td class="table__cell--default">
                <div class="notes__textbox">
                  {{ item.response }}
                </div>
              </td>
              <td class="table__head--text"
                v-if="checkroles(['admin'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)">
                <button type="button" class="employee_delete" @click="deleteSalesOrderNote(item.id, key)">
                  Delete
                </button>
              </td>
              <td class="table__head--text"
                v-if="checkroles(['admin'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)">
                <button v-b-modal.update @click="getIdFromRow(item)" type="button"
                  class="employee_edit">Reactie</button>
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <b-modal id="create" ref="note_modal">
            <template #modal-header>
              <h2>Voeg notitie toe</h2>
            </template>
            <textarea class="form-control" id="note_field" rows="3" v-model.lazy="note_modal" placeholder="waar, wanneer, wat, waarom...."></textarea>
            <input id="image-file" type="file" class="m-2" />
            <template #modal-footer>
              <div class="w-100">
                <b-button variant="primary" size="sm" class="float-right" @click="postNote">
                  Toevoegen
                </b-button>
              </div>
            </template>
          </b-modal>
        </div>
        <div>
          <b-modal id="update" ref="note_modal_2">
            <template #modal-header>
              <h2>Voeg reactie toe</h2>
            </template>
            <textarea class="form-control" id="note_field" rows="3" v-model.lazy="response_modal"></textarea>
            <template #modal-footer>
              <div class="w-100">
                <b-button variant="primary" size="sm" class="float-right" @click="updateResponse">
                  Toevoegen
                </b-button>
              </div>
            </template>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import request from '@/functions/request.js'
import { msalInstance } from "vue-msal-browser";
import Loading from "@/components/Loading";
import hasIndex from "@/functions/hasIndex";

export default {
  components: { Loading },
  data: () => ({
    sales_order_notes: null,
    loading: true,
    note_modal: null,
    response_modal: null,
    id: null,

  }),
  created() {
    this.getData();
  },
  methods: {
    getData() {
      request('get-sales-order-program-notes', "GET").then(({ sales_order_notes }) => {
        this.sales_order_notes = sales_order_notes;
        this.loading = false;
      })
    },

    postNote() {
      var input = document.getElementById("image-file");

      var headers = new Headers();
      headers.append("KLEYN-APIKEY", process.env.VUE_APP_APIKEY);

      var data = new FormData();
      data.append("bestand", input.files[0]);
      data.append("gebruiker", msalInstance.getAllAccounts()[0].idTokenClaims.name);
      data.append("datum", this.moment().format("YYYY-MM-DD HH:mm:ss.SSS"));
      data.append("notitie", this.note_modal);

      var requestOptions = {
        method: "POST",
        headers: headers,
        body: data,
      };

      fetch(`${process.env.VUE_APP_API_URL}sales-order-program-note`, requestOptions)
        .then((response) => response.json())
        .then((responseJson) => {
          let toastProps = {};
          if (hasIndex(responseJson.message, "toegevoegd")) {
            toastProps = {
              message: responseJson.message,
              type: "success",
            };
            this.$refs["note_modal"].hide();
            if (input.files[0]) {
              this.sales_order_notes.unshift({
                gebruiker: msalInstance.getAllAccounts()[0].idTokenClaims.name,
                datum: this.moment().format("YYYY-MM-DD"),
                notitie: this.note_modal,
                bestand: 'bezig met uploaden...'
              });
            } else {
              this.sales_order_notes.unshift({
                gebruiker: msalInstance.getAllAccounts()[0].idTokenClaims.name,
                datum: this.moment().format("YYYY-MM-DD"),
                notitie: this.note_modal,
              });
            }

          } else {
            toastProps = {
              message: responseJson.error,
              type: "danger",
            };
            this.$refs["note_modal"].hide();
          }

          this.$store.dispatch("addNotification", toastProps);
        });
    },

    deleteSalesOrderNote(id, key) {
      if (confirm("notitie verwijderen?")) {
        const data = { id };
        request("delete-sales-order-program-notes", "DELETE", data).then(() => {
          this.sales_order_notes.splice(key, 1);
          location.reload();
        });
      }
    },

    getIdFromRow(item) {
      this.id = item.id
      this.response_modal = item.response;
    },

    updateResponse() {
      const data = {
        id: this.id,
        response: this.response_modal
      };
      request("update-sales-order-program-notes", "PATCH", data).then(() => {
        location.reload();
      });
    }
  },
}
</script>