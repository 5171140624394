<template>
  <div class="flexbox employee_request--container">
    <div class="flex _row w100 flexbox">
      <div class="m2">
        <TextInput placeholder="Voornaam, Achternaam" label="Naam medewerker *" v-model="employee_name" />
        <TextInput placeholder="Voornaam, Achternaam" label="Herhaal naam medewerker *" v-model="verify_employee_name" />
        <TextInput label="Functiebeschrijving *" v-model="employee_function" />
      </div>
      <div class="m2">
        <div class="bold">Selecteer wat dit persoon nodig heeft.</div>
        <Checkbox id="checkbox_benodigdheden" v-for="(item, key) in options_column_2" :key="key" :value="item.value"
          :label="item.text" />
      </div>
      <div class="m2">
        <div class="bold">Wat heeft deze werknemer verder nodig?</div>
        <textarea id="textarea" v-model="employee_needs" placeholder="Typ hier de benodigdheden..." rows="3" max-rows="6"
          input-id="text_vak" class="employee_textarea" />
      </div>
      <div class="m2">
        <TextInput label="Personeelsnummer *" v-model="personnel_number" />
        <small>Bij HR aanvragen</small>
      </div>
      <div class="m2">
        <label><strong>Wanneer begint hij?</strong></label>
        <input type="date" class="form-control label" v-model="begins_on" />
      </div>
    </div>
    <div class="m2 w100">
      <div class="bold">Selecteer de rollen die dit persoon nodig heeft.</div>
      <div class="employee_grid m1">
        <Checkbox id="checkbox_employeeroles" v-for="(item, key) in options_column_1" :key="key" :value="item.value"
          :label="item.text" />
      </div>
      <div class="flex row">
        <button v-on:click="postEmployee()" class="m2 employee_add--button" id="submit_button">
          Verzenden
        </button>
        <loading v-if="loading_submit" class="p-3" />
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/functions/request.js";
import hasIndex from "@/functions/hasIndex";
import TextInput from "@/components/TextInput";
import Checkbox from "@/components/Checkbox.vue";
import { msalInstance } from "vue-msal-browser";
import Loading from '@/components/Loading.vue'

export default {
  components: { TextInput, Checkbox, Loading },
  data: () => ({
    employee_roles: [], // Must be an array reference!
    employee_needs: [],
    options_column_1: [
      { text: "Werkplaats planning", value: "werkplaats_planning" },
      { text: "Verkoop vans", value: "verkoop_vans" },
      { text: "Verkoop trucks", value: "Verkoop_trucks" },
      { text: "Verkoop assistente", value: "verkoop_assistente" },
      { text: "Transport", value: "transport" },
      { text: "TP Sales", value: "tp_sales" },
      { text: "TP Magazijn", value: "tp_magazijn" },
      { text: "TP Demontage", value: "tp_demontage" },
      { text: "TP Ambassadeur", value: "tp_ambassador" },
      { text: "Testcentrum", value: "testcentrum" },
      { text: "Terrein vans", value: "terrein_vans" },
      { text: "Commerciele administratie", value: "commerciele_administratie" },
      { text: "Terrein", value: "terrein" },
      { text: "Receptie", value: "receptie" },
      { text: "Personeelszaken", value: "personeelszaken" },
      { text: "Monteur vans", value: "monteur_vans" },
      { text: "Monteur trucks", value: "monteur_trucks" },
      { text: "Marktonderzoek", value: "marktonderzoek" },
      { text: "Marketing", value: "Marketing" },
      { text: "Lease trucks", value: "lease_trucks" },
      { text: "KCC", value: "kcc" },
      { text: "Inkoop vans", value: "inkoop_vans" },
      { text: "Inkoop trucks", value: "inkoop_trucks" },
      { text: "Binnenkomst", value: "binnenkomst" },
      { text: "ICT", value: "ICT" },
      { text: "Financiele Administratie", value: "financiele_administratie" },
      { text: "Extern verkoop vans", value: "extern_verkoop_vans" },
      { text: "Extern verkoop trucks", value: "extern_verkoop_trucks" },
      { text: "Extern lease vans", value: "extern_lease_vans" },
      { text: "Extern inkoop", value: "extern_inkoop" },
      { text: "Extern ICT", value: "extern_ict" },
      { text: "Directie assistentie", value: "directie_assistentie" },
      { text: "Directie", value: "Directie" },
      { text: "Delivery", value: "Delivery" },
      { text: "Algemeen", value: "algemeen" },
      { text: "Client advisor", value: "client_advisor" }
    ],
    options_column_2: [
      { text: "CRM", value: "crm" },
      { text: "Telefoon", value: "telefoon" },
      { text: "Tablet", value: "tablet" },
      { text: "Laptop", value: "laptop" },
      { text: "Combia sleutel", value: "combia_key" },
      { text: "Poort sleutel", value: "gate_key" },
      { text: "Tag", value: "tag" },
      { text: "Kleyn Park app", value: "kleynparkapp" }
    ],
    employee_name: null,
    employee_function: null,
    verify_employee_name: null,
    tag: null,
    crm: null,
    gate_key: null,
    combia_key: null,
    personnel_number: null,
    begins_on: null,
    kleynparkapp: null,
    submitted: false,
    loading_submit: false,
  }),
  methods: {
    postEmployee() {
      if (!this.submitted) {
        document.querySelector('#submit_button').disabled = true;
        document.querySelector('#submit_button').style.background = 'gray';
        if (this.employee_name !== this.verify_employee_name) {
          document.querySelector('#submit_button').style.background = '#28a745';
          document.querySelector('#submit_button').disabled = false;
          this.$store.dispatch("addNotification", {
            message: "De namen die u heeft ingevoerd komen niet overeen",
            type: "danger",
          });
        } else if (this.personnel_number == null) {
          document.querySelector('#submit_button').disabled = false;
          document.querySelector('#submit_button').style.background = '#28a745';
          this.$store.dispatch("addNotification", {
            message: "Het personeelsnummer is niet ingevoerd (Neem contact op met HR voor een personeelsnummer)",
            type: "danger",
          });
        } else {
          this.submitted = true;
          this.loading_submit = true;
          const cb_roles = document.getElementsByName("checkbox_employeeroles");
          var employee_roles = [];
          for (var i = 0; i < cb_roles.length; i++) {
            if (cb_roles[i].checked) employee_roles.push(cb_roles[i].value)
          }
          const necessities = document.getElementsByName("checkbox_benodigdheden");
          var employee_necessities = [];
          for (var j = 0; j < necessities.length; j++) {
            if (necessities[j].checked) {
              this[necessities[j].value] = 1;
              employee_necessities.push(necessities[j].value);
            } else {
              this[necessities[j].value] = 0;
            }
          }

          const splitname = this.employee_name.split(/(\s+)/).filter(function (e) { return e.trim().length > 0; });
          const voornaam = splitname.shift();
          const achternaam = splitname.pop();
          let tussenvoegsel;
          if (splitname.length == 0) tussenvoegsel = ""
          if (splitname.length == 1) tussenvoegsel = splitname[0]
          else tussenvoegsel = splitname.join(' ')
          const foto = this.employee_name + '.jpg'

          const data = {
            name: this.employee_name,
            function: this.employee_function,
            roles: employee_roles,
            necessities: employee_necessities,
            requested_by: msalInstance.getAllAccounts()[0].idTokenClaims.name,
            requested_email:
              msalInstance.getAllAccounts()[0].idTokenClaims.preferred_username,
            needed: this.employee_needs,
            crm: this.crm ? 1 : 0,
            tag: this.tag ? 1 : 0,
            gate_key: this.gate_key ? 1 : 0,
            combia_key: this.combia_key ? 1 : 0,
            kleynparkapp: this.kleynparkapp ? 1 : 0,
            personnel_number: this.personnel_number,
            begins_on: this.moment(this.begins_on).format('DD-MM-YYYY'),
            first_name: voornaam,
            preposition: tussenvoegsel,
            last_name: achternaam,
            foto: foto
          };
          
          request("create-employee-request", "POST", data).then((values) => {
            if (hasIndex(values.message, "aangevraagd")) {
              this.loading_submit = false;
              this.employee_name = null;
              this.verify_employee_name = null;
              this.employee_function = null;
              this.employee_roles = [];
              this.employee_necessities = null;
              this.employee_needs = [];
              this.begins_on = "";
              this.personnel_number = null;
              this.$store.dispatch("addNotification", {
                message: values.message,
                type: "success",
              });
              this.submitted = false;
              document.querySelector('#submit_button').disabled = false;
              document.querySelector('#submit_button').style.background = '#28a745';
            } else {
              this.submitted = false;
              this.loading_submit = false;
              document.querySelector('#submit_button').disabled = false;
              document.querySelector('#submit_button').style.background = '#28a745';
              this.$store.dispatch("addNotification", {
                message: values.error,
                type: "danger",
              });
            }
          });
        }
      }
    },
  },
};
</script>
