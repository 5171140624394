<template>
  <div class="labelcontainer">
    <label
      :class="{
        'bold _xl': size === 'xl',
        'bold _large': size === 'large',
        'bold _medium': size === 'medium',
        'bold _small': size === 'small',
      }"
    >
      {{ label }}:
    </label>
    <input
      v-if="value == 'null' || value == '..onbekend..' || value == '€ null' || value == null || value == undefined || value == 'undefined'"
      type="text"
      name="soort"
      value=""
      class="form-control label"
      readonly
    />
    <input
      v-else
      type="text"
      name="soort"
      :value="`${value}`"
      class="form-control label"
      readonly
    />
  </div>
</template>
<script>
export default {
  props: {
    label: String,
    value: String,
    size: {
      type: String,
      default: "xl",
    },
  },
};
</script>
<style>
._xl {
  min-width: 138px;
}

._large {
  min-width: 120px;
}

._medium {
  min-width: 100px;
}

._small {
  min-width: 75px;
}

.labelcontainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 400px;
}
</style>
