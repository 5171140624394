const routes = [
  {
    path: "/",
    name: "Home",
    meta: { roles: ["user", "admin"] },
    component: () => import(/* webpackChunkName: "Home" */ "@/views/Home.vue"),
  },
  {
    path: "/about",
    name: "About",
    meta: { roles: ["user", "admin"] },
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/About.vue"),
  },
  {
    path: "/profile",
    name: "ProfileData",
    meta: { roles: ["admin"] },
    component: () =>
      import(/* webpackChunkName: "profile" */ "@/views/ProfileData.vue"),
  },
  {
    path: "/VerkopenPerWeek",
    name: "SalesPerWeek",
    meta: { roles: ["user"] },
    component: () =>
      import(/* webpackChunkName: "SalesPerWeek" */ "@/views/SalesPerWeek.vue"),
  },
  {
    path: "/medewerker_onderhoud",
    name: "ManageEmployees",
    meta: { roles: ["admin", "hr", "marketing"] },
    component: () =>
      import(
        /* webpackChunkName: "Werknemers" */ "@/views/Employees/ManageEmployees.vue"
      ),
  },
  {
    path: "/items/item/nummer/:id/bu/:bu",
    name: "ItemPage",
    meta: { roles: ["user", "admin"] },
    props: true,
    component: () =>
      import(/* webpackChunkName: "ItemPage" */ "@/views/ItemPage.vue"),
  },
  {
    path: "/binnengekomen",
    name: "Binnengekomen",
    meta: {
      roles: [
        "admin",
        "binnenkomst",
        "finad",
        "kcc",
        "telefonistes",
        "it",
        "hr",
        "comad",
        "verkoper_trucks",
        "verkoper_vans",
        "secretariaat",
        "delivery",
        "terrein",
        "werkplaats",
        "marketing",
        "inkoper",
        "management",
      ],
    },
    component: () =>
      import(
        /* webpackChunkName: "binnengekomen" */ "@/views/RecievedPerDay.vue"
      ),
  },
  {
    path: "/nieuwewerknemer",
    name: "nieuwewerknemer",
    meta: { roles: ["admin", "hr", "it", "managers", "management"] },
    component: () =>
      import(
        /* webpackChunkName: "nieuwewerknemer" */ "@/views/Employees/RequestNewEmployee.vue"
      ),
  },
  {
    path: "/gekocht_vandaag/:bu",
    name: "Gekochtvandaag",
    meta: {
      roles: [
        "admin",
        "finad",
        "it",
        "comad",
        "verkoper_vans",
        "verkoper_trucks",
        "inkoper",
        "management",
      ],
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "gekochtvandaag" */ "@/views/BoughtPerDay.vue"
      ),
  },
  {
    path: "/trustpilot",
    name: "Trustpilot",
    meta: { roles: ["user"] },
    component: () =>
      import(/* webpackChunkName: "trustpilot" */ "@/views/Trustpilot.vue"),
  },
  {
    path: "/gereserveerd_per_inkoper/:bu",
    name: "Gereserveerd",
    meta: { roles: ["management", "inkoper", "verkopers_vans", "verkopers_trucks", "admin"] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "ReservedPerBuyer" */ "@/views/ReservedPerBuyer.vue"
      ),
  },
  {
    path: "/verkoop_orders/:bu",
    name: "Verkooporders",
    meta: { roles: ["admin", "management", "delivery"] },
    props: true,
    component: () => import("@/views/SalesOrders.vue"),
  },
  {
    path: "/transport_wegbrengen/:bu",
    name: "wegbrengen",
    meta: {
      roles: [
        "finad",
        "admin",
        "it",
        "comad",
        "verkoper_vans",
        "verkoper_trucks",
        "secretariaat",
        "delivery",
        "terrein",
        "inkoper",
        "management",
        "transport"
      ],
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "DeliverOrders" */ "@/views/DeliverOrders.vue"
      ),
  },
  {
    path: "/voorraad/:bu",
    name: "voorraad",
    meta: {
      roles: ["admin", "finad", "it", "comad", "inkoper", "management"],
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "StockPerBuyer" */ "@/views/StockPerBuyer.vue"
      ),
  },
  {
    path: "/resultaten/:bu",
    name: "resultaten",
    meta: { roles: ["admin", "it", "secretariaat", "management", "inkoper"] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "ResultsPerSalesman" */ "@/views/ResultsPerMonth.vue"
      ),
  },
  {
    path: "/klanten/klant/nummer/:id",
    name: "klantpagina",
    meta: {
      roles: [
        "finad",
        "admin",
        "kcc",
        "it",
        "comad",
        "verkoper_trucks",
        "verkoper_vans",
        "delivery",
        "marketing",
        "management",
        "telefonistes",
        "magazijn",
        "transport",
        "inkoper"
      ],
    },
    props: true,
    component: () =>
      import(/* webpackChunkName: "CustomerPage" */ "@/views/CustomerPage.vue"),
  },
  {
    path: "/noaccess",
    name: "noaccess",
    meta: { roles: ["user", "admin"] },
    component: () =>
      import(/* webpackChunkName: "Noaccess" */ "@/views/NoAccess.vue"),
  },
  {
    path: "/voorraadstand/:bu",
    name: "voorraadstand",
    meta: { roles: ["user"] },
    props: true,
    component: () => import("@/views/StockPosition.vue"),
  },
  {
    path: "/VoorraadStandDetails/:bu/:title",
    name: "voorraadstanddetails",
    meta: { roles: ["user"] },
    props: true,
    component: () => import("@/views/StockPositionKindDetails.vue"),
  },
  {
    path: "/voorraadstandafschriftdetails/:bu/:group/:status",
    name: "voorraadstandafschriftdetails",
    meta: { roles: ["user"] },
    props: true,
    component: () => import("@/views/StockPositionWriteOffDetails.vue"),
  },
  {
    path: "/ontvangstenperweek/:bu",
    name: "ontvangstenperweek",
    meta: { roles: ["user", "admin"] },
    props: true,
    component: () => import("@/views/RecievedPerWeek.vue"),
  },
  {
    path: "/openstaandeinkooporders/:bu",
    name: "openstaandeinkooporders",
    meta: { roles: ["user", "admin"] },
    props: true,
    component: () => import("@/views/OpenPurchaseOrders.vue"),
  },
  {
    path: "/verwacht_deze_week",
    name: "verwachtdezeweek",
    meta: { roles: ["user", "admin"] },
    component: () => import("@/views/ExpectedThisWeek.vue"),
  },
  {
    path: "/medewerkers",
    name: "Employees",
    meta: { roles: ["user"] },
    component: () => import("@/views/Employees/Employees.vue"),
  },
  {
    path: "/verkoop_orders_persoonlijk/:bu",
    name: "verkooporderperpersoon",
    meta: { roles: ["user", "admin"] },
    props: true,
    component: () => import("@/views/SalesOrdersPerPerson.vue"),
  },
  {
    path: "/openstaande_bedragen",
    name: "openstaandebedragen",
    meta: { roles: ["user", "admin"] },
    component: () => import("@/views/OpenAmountsPerPerson.vue"),
  },
  {
    path: "/afleveringen_per_maand/:bu",
    name: "afleveringenpermaand",
    meta: { roles: ["admin", "user"] },
    props: true,
    component: () => import("@/views/DeliveriesPerPerson.vue"),
  },
  {
    path: "/transport_halen/:bu",
    name: "halen",
    meta: {
      roles: [
        "finad",
        "admin",
        "it",
        "comad",
        "verkoper_trucks",
        "secretariaat",
        "delivery",
        "terrein",
        "inkoper",
        "management",
        "transport"
      ],
    },
    props: true,
    component: () => import("@/views/PickupOrders.vue"),
  },
  {
    path: "/ontbrekende_kentekens/:bu",
    name: "ontbrekendekentekens",
    meta: { roles: ["admin", "user"] },
    props: true,
    component: () => import("@/views/MissingLicensePlates.vue"),
  },
  {
    path: "/voertuig_wijzigingen/:bu",
    name: "voertuigwijzigingen",
    meta: { roles: ["admin", "user"] },
    props: true,
    component: () => import("@/views/VehicleChanges.vue"),
  },
  {
    path: "/voorraadstadagen",
    name: "voorraadstadagen",
    meta: { roles: ["admin", "user", "marketing", "binnenkomst"] },
    props: true,
    component: () => import("@/views/StockStageDays.vue"),
  },
  {
    path: "/wordt_gebracht/:bu",
    name: "wordtgebracht",
    meta: {
      roles: [
        "finad",
        "admin",
        "it",
        "comad",
        "verkoper_trucks",
        "secretariaat",
        "delivery",
        "terrein",
        "inkoper",
        "management",
        "transport"
      ],
    },
    props: true,
    component: () => import("@/views/BroughtOrders.vue"),
  },
  {
    path: "/top20klanten/:bu",
    name: "top20customers",
    meta: { roles: ["admin", "management", "marketing", "verkoper_vans", "verkoper_trucks"] },
    props: true,
    component: () => import("@/views/Top20Customers.vue"),
  },
  {
    path: "/mutations/:bu",
    name: "mutations",
    meta: { roles: ["management", "verkoper_trucks", "verkoper_vans", "admin", "transport"] },
    props: true,
    component: () => import("@/views/Mutations.vue"),
  },
  {
    path: "/afleveringen_per_week/:bu",
    name: "afleveringenperweek",
    meta: { roles: ["admin", "management", "delivery"] },
    props: true,
    component: () => import("@/views/DeliveriesPerWeek.vue"),
  },
  {
    path: "/openstaand_totaal",
    name: "openstaandebedragentotaal",
    meta: { roles: ["admin", "management", "delivery"] },
    component: () => import("@/views/OpenAmountsTotal.vue"),
  },
  {
    path: "/search_results",
    name: "searchpage",
    meta: { roles: ["user"] },
    props: true,
    component: () => import("@/views/SearchPage.vue"),
  },
  {
    path: "/leveranciers/leverancier/nummer/:id/bu/0",
    name: "leverancier",
    meta: { roles: ["admin", "management", "delivery", "inkoper", "finad", "comad", "transport"] },
    props: true,
    component: () => import("@/views/SupplierPage.vue"),
  },
  {
    path: "/vooruit_betaald/:bu",
    name: "vooruitbetaald",
    meta: { roles: ["admin", "management", "transport"] },
    props: true,
    component: () => import("@/views/PayedUpfront.vue"),
  },
  {
    path: "/resultaat_per_inkoper/:bu",
    name: "resultaatperinkoper",
    meta: {roles: ["admin", "management", "it", "inkoper"] },
    props: true,
    component: () => import("@/views/ResultsPerBuyer.vue"),
  },
  {
    path: "/resultaat_per_verkoper/:bu",
    name: "resultaatperverkoper",
    meta: {roles: ["admin", "management", "it", "inkoper"] },
    props: true,
    component: () => import("@/views/ResultsPerSeller.vue"),
  },
  {
    path: "/resultaat_per_verkoper/:bu/:name/:year",
    name: "klantenperverkoper",
    meta: { roles: ["admin", "management", "it"] },
    props: true,
    component: () => import("@/views/CustomersPerSeller.vue"),
  },
  {
    path: "/test",
    name: "test",
    meta: { roles: ["admin", "management", "transport"] },
    props: true,
    component: () => import("@/views/Test.vue"),
  },
  {
    path: "/margin_control/:bu",
    name: "margincontrol",
    meta: {roles: ["admin", "management", "it"] },
    props: true,
    component: () => import("@/views/MarginControl.vue"),
  },
  {
    path: "/programs",
    name: "programs",
    meta: {roles: ["user"] },
    component: () => import("@/views/Programs.vue"),
  },
  {
    path: "/rdw/:bu",
    name: "rdw",
    meta: {roles: ["it", "management", "binnenkomst"] },
    props: true,
    component: () => import("@/views/Rdw.vue"),
  },
  {
    path: "/marge_controle_persoonlijk/:bu",
    name: "margincontrolpersonal",
    meta: {roles: ["it", "management", "user"] },
    props: true,
    component: () => import("@/views/MarginControlPerPerson.vue"),
  },
  {
    path: "/budget_verdeling/:bu",
    name: "budgetverdeling",
    meta: {roles: ["it", "management", "admin", "inkoper"] },
    props: true,
    component: () => import("@/views/BudgetPage.vue"),
  },
  {
    path: "/Leverancier_top_100/:bu",
    name: "leveranciertop100",
    meta: {roles: ["it", "management"] },
    props: true,
    component: () => import("@/views/Top100Suppliers.vue"),
  },
  {
    path: "/Leverancier_inzicht/:bu",
    name: "leverancierinzicht",
    meta: {roles: ["it", "management", "duo_vans"] },
    props: true,
    component: () => import("@/views/SupplierTop5.vue"),
  },
  {
    path: "/Leverancier_inzicht_persoonlijk/:bu",
    name: "leverancierinzichtperpersoon",
    meta: {roles: ["it", "management", "inkoper"] },
    props: true,
    component: () => import("@/views/SupplierTop5PerPerson.vue"),
  },
  {
    path: "/post_nieuws",
    name: "nieuwsposter",
    meta: {roles: ["admin", "marketing", "secretariaat"] },
    props: true,
    component: () => import("@/views/NieuwsPoster.vue"),
  },
  {
    path: "/verkoop_order_details/:id",
    name: "verkooporderdetails",
    meta: {roles: ["it", "management", "admin", "verkoper_vans", "verkoper_trucks", "inkoper", "finad"]},
    props: true,
    component: () => import('@/views/SalesOrderDetails.vue')
  },
  {
    path: "/bestanden",
    name: "bestanden",
    meta: {roles: ["secretariaat", "hr", "admin", "it", "management"]},
    props: true,
    component: () => import('@/views/FileUpload.vue'),
  },
  {
    path: "/landen_overzicht/:bu",
    name: "landenoverview",
    meta: {roles: ["user"]},
    props: true,
    component: () => import('@/views/CountryOverview.vue'),
  },
  {
    path: "/aangevraagde_medewerkers",
    name: "requestedemployees",
    meta: {roles:["it", "hr", "admin"]},
    props: true,
    component: () => import('@/views/Employees/RequestedEmployees.vue'),
  },
  {
    path: "/deliveries_per_day/:bu",
    name: "afleveringenperdag",
    meta: {roles:["admin", "it", "verkoper_vans", "verkoper_trucks", "management"]},
    props: true,
    component: () => import('@/views/DeliveriesPerDay.vue'),
  },
  {
    path: "/stock_history_year/:bu",
    name: "stockhistoryyear",
    meta: {roles:["admin", "it", "management"]},
    props: true,
    component: () => import('@/views/StockHistoryYear.vue'),
  },
  {
    path: "/sales_order_franchise/:franchise",
    name: "salesordersfranchise",
    meta: {roles:["extern", "it", "admin", "A28", "A73", "camionette", "management", "duo_vans"]},
    props: true,
    component: () => import('@/views/FranchiseSalesOrders.vue'),
  },
  {
    path: "/stock_per_franchise/:franchise",
    name: "stockperfranchise",
    meta: {roles:["extern", "it", "admin", "A28", "A73", "camionette", "management", "duo_vans"]},
    props: true,
    component: () => import('@/views/StockPerFranchise.vue'),
  },
  {
    path: "/results_per_franchise/:franchise",
    name: "resultsperfranchise",
    meta: {roles:["extern", "it", "admin", "A28", "A73", "camionette", "management", "duo_vans"]},
    props: true,
    component: () => import('@/views/ResultsPerFranchise.vue'),
  },
  {
    path: "/inspection_report_list/:bu",
    name: "inspectionrapportlist",
    meta: {roles:["it", "admin", "management", "inkoper", "verkoper_trucks", "verkoper_vans", "binnenkomst", "werkplaats"]},
    props: true,
    component: () => import('@/views/InspectionRapportList.vue'),
  },
  {
    path: "/inspection_report/:itemno",
    name: "inspectionrapport",
    meta: {roles:["it", "admin", "management", "inkoper", "verkoper_trucks", "verkoper_vans", "binnenkomst", "werkplaats"]},
    props: true,
    component: () => import('@/views/InspectionReport.vue'),
  },
  {
    path: "/export_documenten",
    name: "exportdocumenten",
    meta: {roles:["user"]},
    props: true,
    component: () => import('@/views/ExportDocumentsPerPerson.vue'),
  },
  {
    path: "/deliveries_per_franchise/:franchise",
    name: "deliveriesperfranchise",
    meta: {roles:["user", "management", "duo_vans"]},
    props: true,
    component: () => import('@/views/DeliveriesPerMonthPerFranchise.vue'),
  },
  {
    path: "/settings",
    name: "settings",
    meta: {roles:["admin"]},
    props: true,
    component: () => import('@/views/Settings.vue'),
  },
  {
    path: "/manage_files",
    name: "managefiles",
    meta: {roles:["hr", "management", "it", "admin", "secretariaat"]},
    props: true,
    component: () => import('@/views/ManageFiles.vue'),
  },
  {
    path: "/planning/:bu",
    name: "planning",
    meta: {roles:["hr", "management", "it", "admin", "werkplaats", "delivery", "transport"]},
    props: true,
    component: () => import('@/views/VansPlanning.vue'),
  },
  {
    path: "/voorraad_per_persoon/:bu",
    name: "stockperperson",
    meta: {roles:["user"]},
    props: true,
    component: () => import('@/views/StockPerBuyerPerPerson.vue'),
  },
  {
    path: "/gekocht_per_land/:bu",
    name: "boughtpercountry",
    meta: {roles:["management", "admin"]},
    props: true,
    component: () => import('@/views/BoughtPerCountry.vue'),
  },
  {
    path: "/landen_jaaroverzicht_details/:bu/:year/:countryCode/:country",
    name: "countryoverviewdetails",
    meta: {roles:["management", "admin", "comad", "inkoper", "verkoper_trucks", "verkoper_vans"]},
    props: true,
    component: () => import('@/views/CountryOverviewDetails.vue'),
  },
  {
    path: "/verkocht_per_inkoper/:bu",
    name: "soldperbuyer",
    meta: {roles:["management", "inkoper", "admin"]},
    props: true,
    component: () => import('@/views/SoldPerBuyer.vue'),
  },
  // {
  //   path: "/newbudgetbreakdown",
  //   name: "soldperbuyer",
  //   meta: {roles:["management", "admin"]},
  //   component: () => import('@/views/newBudgetWithAmounts.vue'),
  // },
  {
    path: "/afgeprijsdevoertuigen/:bu",
    name: "discounteditems",
    meta: {roles:["management", "admin"]},
    props: true,
    component: () => import('@/views/DiscountedItems.vue'),
  },
  {
    path: "/afwezigheid",
    name: "absenceregistration",
    meta: {roles:["user"]},
    component: () => import('@/views/AbsenceRegistration.vue'),
  },
  {
    path: "/biplayground",
    name: "biplayground",
    meta: {roles:["management", "admin", "it"]},
    component: () => import('@/views/PowerBIPlayground.vue'),
  },
  {
    path: "/examinations/:bu",
    name: "itemexaminations",
    props: true,
    meta: {roles:["management", "admin", "it", "werkplaats", "inkoper", "verkoper_trucks", "verkoper_vans"]},
    component: () => import('@/views/ItemExaminationsPerDay.vue'),
  },  
  {
    path: "/truckparts/aanvragen",
    name: "truckpartsRequests",
    meta: {roles:["management", "admin", "it",]},
    component: () => import('@/views/TruckpartsRequests.vue'),
  },
  {
    path: "/openstaande_inruilers",
    name: "openTradeins",
    meta: {roles:["management", "admin", "it",]},
    component: () => import('@/views/OpenTradeins.vue'),
  },
  {
    path: "/openstaande_posten_per_maand",
    name: "OpenPostsPerMonth",
    meta: {roles:["management", "admin", "it",]},
    component: () => import('@/views/OpenPostsPerMonth.vue'),
  },
  {
    path: "/inkoop_provisie_lijst",
    name: "ItemBuyProvisionsList",
    meta: {roles:["management", "admin", "it",]},
    component: () => import('@/views/ItemBuyProvisionsList.vue'),
  },
  {
    path: "/inkoop_duos_soort/:bu",
    name: "purchaseduosoort",
    props: true,
    meta: {roles:["management", "admin", "it", "duo_vans", "duo_trucks", "duo_trailers"]},
    component: () => import('@/views/PurchaseDuos/TurnOver/PurchaseDuoKinds.vue'),
  },
  {
    path: "/inkoop_duos_structure/:bu/:kind",
    name: "purchaseduostructure",
    props: true,
    meta: {roles:["management", "admin", "it", "duo_vans", "duo_trucks", "duo_trailers"]},
    component: () => import('@/views/PurchaseDuos/TurnOver/PurchaseDuoStructure.vue'),
  },
  {
    path: "/inkoop_duos_brand/:bu/:kind/:structure",
    name: "purchaseduobrand",
    props: true,
    meta: {roles:["management", "admin", "it", "duo_vans", "duo_trucks", "duo_trailers"]},
    component: () => import('@/views/PurchaseDuos/TurnOver/PurchaseDuoBrand.vue'),
  },
  {
    path: "/inkoop_duos_items/:bu/:kind/:structure/:brand",
    name: "purchaseduoitems",
    props: true,
    meta: {roles:["management", "admin", "it", "duo_vans", "duo_trucks", "duo_trailers"]},
    component: () => import('@/views/PurchaseDuos/TurnOver/PurchaseDuoItems.vue'),
  },
  {
    path: "/inkoop_duo_stadagen/:bu",
    name: "purchaseduostadagen",
    props: true,
    meta: {roles:["management", "admin", "it", "duo_vans", "duo_trucks", "duo_trailers"]},
    component: () => import('@/views/PurchaseDuos/PurchaseDuoStadagenPerBu.vue'),
  },
  {
    path: "/inkoop_duo_verkoop_orders/:bu",
    name: "purchaseduosalesorders",
    props: true,
    meta: {roles:["management", "admin", "it", "duo_vans", "duo_trucks", "duo_trailers"]},
    component: () => import('@/views/PurchaseDuos/PurchaseDuoSalesOrders.vue'),
  },
  {
    path: "/inkoop_duo_inkoopprovisie_lijst/:bu",
    name: "purchaseduoitemprovisionlist",
    props: true,
    meta: {roles:["management", "admin", "it", "duo_vans", "duo_trucks", "duo_trailers"]},
    component: () => import('@/views/PurchaseDuos/PurchaseDuoItemProvisionList.vue'),
  },
  {
    path: "/leverancier_voorraad_verkopen",
    name: "supplierstocksold",
    props: true,
    meta: {roles:["management", "admin", "it", "duo_vans"]},
    component: () => import('@/views/SuppliersStockSold.vue'),
  },
  {
    path: "/leverancier_voorraad_verkopen/:levnr",
    name: "supplierstocksolditems",
    props: true,
    meta: {roles:["management", "admin", "it", "inkoper"]},
    component: () => import('@/views/SuppliersStockSoldItems.vue'),
  },
  {
    path: "/langstaand_verkocht/:bu",
    name: "VehiclesStockSoldLong",
    props: true,
    meta: {roles:["management", "admin", "duo_vans", "duo_trucks", "duo_trailers"]},
    component: () => import('@/views/VehiclesStockSoldLong.vue'),
  },
  {
    path: "/afgeleverde_voertuigen_per_week/:bu",
    name: "purchaseduodeliveriesperweek",
    props: true,
    meta: {roles:["management", "admin", "duo_trucks"]},
    component: () => import('@/views/PurchaseDuos/PurchaseDuoDeliveriesPerWeek.vue'),
  },
  {
    path: "/afgeleverde_voertuigen_per_maand/:bu",
    name: "purchaseduodeliveriespermaand",
    props: true,
    meta: {roles:["management", "admin", "duo_trucks"]},
    component: () => import('@/views/PurchaseDuos/PurchaseDuoDeliveriesPerMonth.vue'),
  },
  {
    path: "/inkoop_budget_mutaties/:bu",
    name: "purchaseduomutations",
    props: true,
    meta: {roles:["management", "admin", "duo_trucks"]},
    component: () => import('@/views/PurchaseDuos/PurchaseDuoPurchaseBudgetMutations.vue'),
  },
  {
    path: "/verwacht_deze_week/:bu",
    name: "purchaseduoexpectedperweek",
    props: true,
    meta: {roles:["management", "admin", "duo_trucks", "duo_vans", "duo_trailers"]},
    component: () => import('@/views/PurchaseDuos/PurchaseDuoExpectedPerBu.vue'),
  },
  {
    path: "/ongeval_melden",
    name: "ongevalmelden",
    props: true,
    meta: {roles:["management", "admin", "veiligheid"]},
    component: () => import('@/views/SalesOrdersProgramNotes.vue'),
  },
  {
    path: "/purchase_analysis/:bu",
    name: "purchaseanalysis",
    props: true,
    meta: {roles:["management", "admin"]},
    component: () => import('@/views/PurchaseAnalysis.vue'),
  },
  {
    path: "/dagen_verkocht_verdeling/:bu",
    name: "daysvdistribution",
    props: true,
    meta: {roles:["management", "admin", 'duo_trucks', 'duo_vans', 'duo_trailers']},
    component: () => import('@/views/DaysVDistributionList.vue'),
  },
  {
    path: "/near_miss",
    name: "nearmiss",
    props: true,
    meta: {roles:["user"]},
    component: () => import('@/views/NearMiss/NearMiss.vue'),
  },
  {
    path: "/near_miss_incidents",
    name: "nearmissincident",
    props: true,
    meta: {roles:["management", "admin", "veiligheid"]},
    component: () => import('@/views/NearMiss/NearMissIncidents.vue'),
  },
  {
    path: "/planned_with_open_wo",
    name: "plannedwithopenwo",
    props: true,
    meta: {roles:["hr", "management", "it", "admin", "werkplaats", "delivery", "transport"]},
    component: () => import('@/views/PlannedWithOpenWo.vue'),
  },
  {
    path: "/brought_and_pickup_orders_daily",
    name: "broughtandpickupordersdaily",
    props: true,
    meta: {roles:["hr", "management", "it", "admin", "werkplaats", "delivery", "transport", "binnenkomst"]},
    component: () => import('@/views/broughtPickupDaily.vue'),
  },
  {
    path: "/transport_list",
    name: "transportlist",
    props: true,
    meta: {roles:["hr", "management", "it", "admin", "werkplaats", "delivery", "transport", "binnenkomst"]},
    component: () => import('@/views/TransportList.vue'),
  },
  {
    path: "/workshop_control_list",
    name: "workshopcontrollist",
    props: true,
    meta: {roles:["hr", "management", "it", "admin", "werkplaats", "transport", "binnenkomst"]},
    component: () => import('@/views/WorkshopControlList.vue'),
  },
  {
    path: "/inkoop_duo_stadagen_sorted/:bu",
    name: "sortedstagedays",
    meta: {roles:["duo_trucks", "management", "it", "admin"]},
    component: () => import('@/views/PurchaseDuos/PurchaseDuoStadagenPerBuSorted.vue'),
  },
  {
    path: "/werknemer_verandering_doorgeven",
    name: "changeemployeeform",
    meta: {roles:["admin", "hr", "it", "managers", "management"]},
    component: () => import('@/views/Employees/RequestEmployeeChangeForm.vue'),
  },
];

export default routes;